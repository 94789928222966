// The Axios configuration file axiosConfig.ts is imported.
// This ensures that Axios is configured with CSRF token handling
// and other settings before any component or service uses it.

import App from './App.vue'; // Components
import pinia from '@/store/index'; // Import the Pinia store instance
import ClickTrackerService from '@/services/admin_actions/clickTrackerService'; // Import the Click Tracker Service
import { createApp } from 'vue'; // Composables
import { registerPlugins } from '@/plugins'; // Plugins
import { initializeApiServices } from '@/services/apiServiceCSRF'; // API Services Initialization (including CSRF token fetching)
import '@/styles/tech_variables.scss'; // Styles
import '@/styles/tech_styling.scss'; // Styles

// Setup jQuery overrides

// Initialize the Click Tracker Service without arguments
const clickTracker = new ClickTrackerService();
clickTracker.init();

const app = createApp(App); // Create the app

initializeApiServices()
  .then(async () => {
    registerPlugins(app);
    app.use(pinia); // Use the Pinia store
    app.mount('#app');
  })
  .catch((error) => {
    // console.groupCollapsed('API Service Initialization Error');
    console.error(error);
    // console.groupEnd();
    // Handle the error appropriately
  });
