// Composables
import { createRouter, createWebHistory } from 'vue-router';
import { setSiteMode } from '@/services/mode/siteModeService';
declare let $: any;

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    component: () => import('@/layouts/DefaultLayout.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/SitePages/Dashboard.vue'),
        meta: { hideCommunitiesWidget: true }, // Add this line to hide the communities widget
      },
      // {
      //   path: 'menu_login',
      //   name: 'MenuLogin',
      //   component: () => import('@/views/UserManagement/MenuLogin.vue'),
      // },
      {
        path: 'skillstesting',
        name: 'SkillsTesting',
        component: () => import('@/views/SitePages/SkillsTesting.vue'),
      },
      {
        path: 'players',
        name: 'Players',
        component: () => import('@/views/SitePages/Players.vue'),
      },
      {
        path: 'info',
        name: 'Info',
        component: () => import('@/views/AdminPages/Info.vue'),
      },
      // {
      //   path: ':catchAll(.*)', // This is a second catch-all route designed to catch any routes that don't match the above routes
      //   name: 'CatchAll', // This catch all needs review
      //   component: () => import('@/views/AdminPages/Error404.vue'),
      // },
      {
        path: 'boxes',
        name: 'Boxes',
        component: () => import('@/views/SitePages/Boxes.vue'),
      },
      {
        path: 'box',
        name: 'Box',
        component: () => import('@/views/SitePages/BoxDetail.vue'),
      },
      {
        path: 'search',
        name: 'Search',
        component: () => import('@/views/SitePages/Search.vue'),
        meta: { hideSearchWidget: true }, // Add this line to hide the search widget
      },
      {
        path: 'invites',
        name: 'Invites',
        component: () => import('@/views/SitePages/Invites.vue'),
      },
      {
        path: 'add_result',
        name: 'AddResult',
        component: () => import('@/views/SitePages/AddResult.vue'),
      },
      {
        path: 'demo',
        name: 'Demo',
        component: () => import('@/views/AdminPages/Demo.vue'),
      },
      {
        path: 'upload_results_file',
        name: 'Upload_Results_File',
        component: () => import('@/views/ToolsPages/Upload_Results_File.vue'),
      },
      {
        path: 'pre_event_override',
        name: 'Pre_Event_Override',
        component: () => import('@/views/ToolsPages/Pre_Event_Override.vue'),
      },
      {
        path: 'import_results',
        name: 'Import_Results',
        component: () => import('@/views/ToolsPages/Import_Results.vue'),
      },
      {
        path: 'variable_print',
        name: 'Variable_Print',
        component: () => import('@/views/ToolsPages/Variable_Print.vue'),
      },
      {
        path: ':catchAll(.*)',
        name: 'DynamicContent',
        component: () => import('@/views/AdminPages/DynamicContent.vue'),
      },
    ],
  },
  {
    path: '/admin',
    name: 'Admin',
    redirect: '/admin/home',
    component: () => import('@/layouts/DefaultLayout.vue'),
    children: [
      {
        path: 'home',
        name: 'AdminPanel',
        component: () => import('@/views/AdminPages/AdminPanel.vue'),
      },
      {
        path: 'Classic_Tools',
        name: 'Classic_Tools',
        component: () => import('@/views/ToolsPages/Classic_Tools.vue'),
      },
      {
        path: 'manual_user_merge',
        name: 'Manual_User_Merge',
        component: () => import('@/views/ToolsPages/Manual_User_Merge.vue'),
      },
      {
        path: 'banner_config_tool',
        name: 'Banner_Config_Tool',
        component: () => import('@/views/ToolsPages/Banner_Config_Tool.vue'),
      },
      {
        path: 'add_new_club',
        name: 'add_new_club',
        component: () => import('@/views/ConfigurationPages/Add_New_Club.vue'),
      },
      {
        path: 'add_new_county',
        name: 'add_new_county',
        component: () => import('@/views/ConfigurationPages/Add_New_County.vue'),
      },
      {
        path: 'add_new_admin',
        name: 'add_new_admin',
        component: () => import('@/views/ConfigurationPages/Add_New_Admin.vue'),
      },
      {
        path: 'add_new_feature_detail',
        name: 'add_new_feature_detail',
        component: () => import('@/views/ConfigurationPages/Add_New_Feature_Detail.vue'),
      },
      {
        path: 'add_new_feature',
        name: 'add_new_feature',
        component: () => import('@/views/ConfigurationPages/Add_New_Feature.vue'),
      },
      {
        path: 'add_new_federation',
        name: 'add_new_federation',
        component: () => import('@/views/ConfigurationPages/Add_New_Federation.vue'),
      },
      {
        path: 'add_new_membership',
        name: 'add_new_membership',
        component: () => import('@/views/ConfigurationPages/Add_New_Membership.vue'),
      },
      {
        path: 'edit_club',
        name: 'edit_club',
        component: () => import('@/views/ConfigurationPages/Edit_Club.vue'),
      },
      {
        path: 'edit_county',
        name: 'edit_county',
        component: () => import('@/views/ConfigurationPages/Edit_County.vue'),
      },
    ],
  },{
    path: '/federation/:federation/:page',
    name: 'federation',
    beforeEnter: async (to: any, from: any, next: any) => {
      const { federation, page } = to.params;

        // Call the API to set the user's site mode
        setSiteMode(federation);

        // Redirect to the desired page with mode set in query string
        let query = to.query;
        query.mode = federation;
        next({ path: `/${page}`, query: query});
    },
    component: () => import('@/layouts/DefaultLayout.vue'),
    
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  const existingScripts = document.querySelectorAll('script.defered_script'); // Query all scripts with 'loaded-script' class
  existingScripts.forEach((script) => {
    document.body.removeChild(script); // Remove the existing script from the body
  });
  $(document).off(); // Remove all event listeners
});

export default router;
