import Cookies from "js-cookie";
import { axiosLevelTech } from "../axiosConfig";

function setSiteMode(federation : string) {
  // Call the API to set the user's site mode
  // set a cookie called BaDSquashSiteMode
  // with the value of the federation
  Cookies.set('BaDSquashSiteMode', federation);

  // call the api to set the user's site mode there
  axiosLevelTech.post('/api/userconfig/SetUserSiteMode', {siteMode : federation});

}

export { setSiteMode };