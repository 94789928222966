import { watch } from 'vue';
import { defineStore } from 'pinia';
import { axiosLevelTech } from '@/services/axiosConfig';
import { useUserStore } from './userJWTStore'; // Import the userJWTStore for token changes

// Define an interface for each menu item with optional and required properties
interface SiteMapEntry {
  img?: string; // Optional image URL for the menu item
  mobile?: boolean; // Optional flag indicating mobile-specific visibility
  pos?: string; // Optional position attribute
  title: string; // Required title of the menu item
  href: any; // General type for hyperlink, should be further defined
  highlight?: boolean; // Optional highlight attribute for important items
  style?: string; // Optional style descriptor
  playerid?: string; // Optional player ID for dynamic URLs
  text?: string; // Optional text content for the menu item
}

// Define an interface for the site map structure containing various menu items
interface SiteMap {
  [key: string]: SiteMapEntry | { [key: string]: SiteMapEntry }; // Dynamic keys with nested structure
}

// Define an interface for the overall menu data containing different categories of menu items
interface MenuData {
  site_map: SiteMap; // Structured map of site navigation elements
  top_menu_items: { [key: string]: SiteMapEntry }; // Top menu items collection
  left_menu_items: { [key: string]: SiteMapEntry }; // Left-side menu items collection
  bottom_menu_items: { [key: string]: SiteMapEntry }; // Bottom menu items collection
}

// Define an interface representing the overall state of user data in the store
interface UserDataState {
  menuData: MenuData; // Menu data structured according to MenuData interface
  notifications: any[]; // Array of notifications, type to be further defined as needed
  banners: any[]; // Array of banners, type to be further defined as needed
  jsdefs: any; // JavaScript definitions, type to be further defined as needed
}

// Define the store using the 'defineStore' method from Pinia, providing the store ID and its structure
export const useUserDataStore = defineStore('userData', {
  // Define the initial state using a function that returns the state structure
  state: (): UserDataState => ({
    menuData: {
      site_map: {},
      top_menu_items: {},
      left_menu_items: {},
      bottom_menu_items: {},
    },
    notifications: [],
    banners: [],
    jsdefs: {},
  }),

  // Actions contain methods for modifying the state
  actions: {
    // Asynchronous action to fetch user data from an API endpoint
    async fetchUserData() {
      // console.groupCollapsed('Fetching User Data');
      try {
        const response = await axiosLevelTech.get('/api/menu/GetInitialMenuData');
        // console.log('API Response:', response);
        this.menuData = response.data.data.Menu;
        this.notifications = response.data.data.Notifications;
        this.banners = response.data.data.banners;
        this.jsdefs = response.data.data.JsDefs;

        // console.log('Updated User Data:', {
        //   menuData: this.menuData,
        //   notifications: this.notifications,
        //   banners: this.banners,
        //   jsdefs: this.jsdefs,
        // });
      } catch (error) {
        console.error('Error Fetching User Data:', error);
      } finally {
        // console.groupEnd();
      }
    },
    // Action to mark all notifications as read
    async markNotificationsAsRead() {
      // console.groupCollapsed('Mark Notifications As Read');

      try {
        const response = await axiosLevelTech.post('/api/notification/MarkAsRead');
        // console.log('API response:', response);

        console.groupCollapsed('Mark Notifications As Read: API Response');
        console.log('API Response:', response.data);

        if (response.data.status.toLowerCase() === 'success') {
          console.log('Notifications before update:', this.notifications);

          const updatedNotifications = this.notifications.map((notification) => {
            if (!notification.read) {
              console.log(`Marking notification ID ${notification.noteid} as read.`);
              return { ...notification, read: true };
            }
            return notification;
          });

          this.notifications = [...updatedNotifications]; // Trigger reactivity
          console.log('Notifications successfully marked as read in store.');
        } else {
          console.error('Failed to mark notifications as read:', response.data.message);
        }

        console.log('Updated Notifications State:', this.notifications);
        console.groupEnd();
      } catch (error) {
        console.error('Error calling MarkAsRead API:', error);
      }

      // console.groupEnd();
    },
  },
});

// Setup a watcher to react to token changes and refresh user data
const userDataStore = useUserDataStore();
const userStore = useUserStore();

let initialLoad = true; // Track initial load

watch(
  () => userStore.token,
  (newToken, oldToken) => {
    // console.groupCollapsed('Token Watcher Triggered');
    // console.log('Old Token:', oldToken);
    // console.log('New Token:', newToken);

    if (initialLoad) {
      // console.log('Initial load detected, skipping API call.');
      initialLoad = false; // Skip fetching on initial load
    } else if (newToken !== oldToken) {
      // console.log('Token changed, fetching new user data...');
      userDataStore.fetchUserData(); // Trigger only on token change after initial load
    } else {
      // console.log('No token change detected.');
    }

    // console.groupEnd();
  },
  { immediate: true },
);
